<template>
  <div class="pageBgV">
    <!-- 头部 -->
    <van-row class="personage-head">
      <van-row class="personage-head-image">
        <van-image
          width="4rem"
          height="4rem"
          :src="require('../../assets/img/logo.png')"
          :round="true"
        />
      </van-row>
      <van-row class="personage-head-span">
        <van-row @click="tosetting">
          <b v-if="user.nickname">{{ user.nickname }}</b>
          <b v-else>{{ $t("home.nickname") }}</b>
        </van-row>
        <van-row style="display: flex; align-items: center;">
          <span style="font-size: 0.85rem; color: #888f9c;">
            UID:{{ user.username }}
          </span>
          <img
            class="copyIv"
            src="../../assets/img/copy.svg"
            @click.prevent="onCopyClick()"
            :data-clipboard-text="mUserName"
          />
        </van-row>
      </van-row>
      <div class="rightTop" @click="toservice">
        <img
          class="rightTopIv"
          src="../../../src/assets/img/icon_service.svg"
        />
        <span class="rightTopTv">{{ $t("home.serverurl") }}</span>
      </div>
    </van-row>
    <!-- vip -->
    <!-- <van-row class="personage-member">
      <van-row class="personage-member-a" @click="toservice">
        <span class="personage-member-p1">{{ $t("home.memberVip") }}</span>
        <span class="personage-member-p2">{{ $t("home.vip") }}</span>
        <div class="personage-member-p3V">
          <span class="personage-member-p3">{{ $t("home.vipOpen") }}</span>
        </div>
      </van-row>
    </van-row> -->
    <img class="bottomIv" src="../../assets/img/banner.png" />
    <!-- 列表 -->
    <van-row class="infoRow" style="margin-top: 0.5rem;">
      <van-cell
        :icon="require('../../assets/img/renzheng.svg')"
        :title="$t('home.gj_auth')"
        :border="false"
        is-link
        to="/person/authentication"
      />
      <!-- <van-cell
        :icon="require('../../assets/img/my_card.svg')"
        :title="$t('home.my_card')"
        :border="false"
        is-link
        @click="onCardClick()"
      /> -->
      <van-cell
        :icon="require('../../assets/img/message_list.svg')"
        :title="$t('home.message')"
        :border="false"
        is-link
        to="/trade/contact/list"
      >
        <div v-if="isMsg" slot="default" class="redSpan"></div>
      </van-cell>
      <van-cell
        :icon="require('../../assets/img/serve_my.svg')"
        :title="$t('home.serverurl')"
        :border="false"
        is-link
        :url="serverurl"
      />
      <van-cell
        :icon="require('../../assets/img/help_my.svg')"
        :title="$t('home.help')"
        :border="false"
        is-link
        to="/course"
      />
      <van-cell
        :icon="require('../../assets/img/set_my.svg')"
        :title="$t('home.set')"
        :border="false"
        is-link
        to="/person/setting"
      />
    </van-row>
    <!-- 退出登录 -->
    <div class="logoutV">
      <span class="logoutTv" @click="logout">{{ $t("home.logout") }}</span>
    </div>
  </div>
</template>
<script>
import Clipboard from "clipboard";
export default {
  data() {
    return {
      appLogo: "",
      user: {},
      serverurl: "",
      mUserName: "",
      isMsg:false,//是否显示未读
    };
  },
  created() {
    this.getdata();
    this.getLogo();
    this.getMsg();
  },
  methods: {
    //获取应用名称logo
    async getLogo() {
      console.log("-----", this.getlang());
      const { data } = await this.$http.get("/home/index/getConf");
      if (data) {
        if (data.code === 200) {
          this.appLogo = data.data.imageurl;
        }
      }
    },
    //获取未读消息
    async getMsg() {
      const { data } = await this.$http.get("/home/user/unread");
      if (data) {
        if (data.code === 200) {
          this.isMsg = data.data;
        }
      }
    },
    // 获取首页数据
    async getdata() {
      const { data } = await this.$http.get("/home/home/index");
      if (data) {
        if (data.code === 200) {
          this.user = data.data.user;
          this.mUserName = data.data.user.username;
          this.serverurl = data.data.serverurl;
        }
      }
    },
    onCopyClick() {
      const clipboard = new Clipboard(".copyIv");
      clipboard.on("success", (e) => {
        this.$toast.success(this.$t("common.success"));
      });
      clipboard.on("error", (e) => {
        console.log("------", JSON.stringify(e));
      });
    },
    // 去设置页
    tosetting() {
      this.$router.push("/person/setting");
    },

    onCardClick() {
      this.$router.push({ path: "/assets/card/" + 1 });
    },
    // 客服链接
    toservice() {
      window.location.href = this.serverurl;
    },
    // 退出
    logout() {
      window.localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" scoped>
.pageBgV {
  width: 100%;
  height: 100%;
  background-color: #fff;
}
// 弹出层
.personage-head {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-top: 1rem;
  .personage-head-image {
    margin-right: 3%;
  }
  .personage-head-span {
    text-align: left;
    font-size: 1.33333rem;
    line-height: 2.13333rem;
    flex: 1;
  }

  .copyIv {
    width: 1rem;
    height: 1rem;
    margin-left: 10px;
  }

  .rightTop {
    background-color: #d6e7ff;
    padding: 5px;
    border-radius: 1rem 0 0 1rem;
    display: flex;
    align-items: center;

    .rightTopIv {
      margin-left: 10px;
    }

    .rightTopTv {
      font-weight: 500;
      font-size: 14px;
      color: #df581e;
      margin-left: 5px;
      margin-right: 7px;
    }
  }
}
.bottomIv {
  width: 92%;
}
.personage-member {
  height: 7rem;
  margin-top: 1.12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .personage-member-a {
    width: 100%;
    height: 100%;
    padding: 0 5%;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 1rem;
    background-image: url("../../assets/img/vip_bg.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .personage-member-p1 {
      font-family: "YouSheBiaoTiHei";
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.165px;
      background: linear-gradient(270deg, #fce9b8 0%, #f4cb85 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-style: italic;
      letter-spacing: 0.1em;
      text-align: left;
    }
    .personage-member-p2 {
      font-family: "Alibaba PuHuiTi";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: -0.165px;
      background: linear-gradient(
        270deg,
        rgba(252, 233, 184, 0.85) 0%,
        rgba(244, 203, 133, 0.85) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-align: left;
    }
    .personage-member-p3V {
      margin-top: 0.56rem;
      display: flex;
      justify-content: flex-end;
      .personage-member-p3 {
        background: linear-gradient(270deg, #fae6ad 0%, #fff2c7 100%);
        border-radius: 12.5px;
        color: #333;
        font-size: 0.68rem;
        height: 1.56rem;
        padding-left: 0.87rem;
        padding-right: 0.87rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Alibaba PuHuiTi";
        font-style: normal;
        font-weight: 400;
      }
    }
  }
  .van-col {
    float: left;
    box-sizing: border-box;
    min-height: 1px;
  }
}

// 列表区域
.van-cell {
  font-size: 1rem;
  color: #868a8e !important;
  height: 3rem;
  font-family: "Alibaba PuHuiTi";
  font-style: normal;
  font-weight: 400;
  border-bottom: 0.1px solid #e9f2fd;
  padding-left: 1rem;
  padding-right: 0;
  // margin-left: 1rem;
  // margin-right: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
}

.van-icon__image {
  width: 1.3rem;
  height: 1.3rem;
}
.van-cell__title {
  text-align: left;
  margin-left: 0.4rem;
  flex: 0.9;
  color: #868a8e;
}
.van-cell__value{
  width: 6px;
  max-width: 6px;
  max-height: 6px;
  border-radius: 50%;
  height: 6px;
  background: #F42A2A;
}
.van-cell__right-icon {
  color: #858a8f;
}
// 切换语言
.personage-cut {
  width: 100%;
  position: fixed;
  bottom: 4rem;
  background-color: #f7f7f7;
  height: 2.66667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.98667rem;
  color: #004ea3;
}
// 退出登录
.logoutV {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f5;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1.5rem;
  height: 3rem;
  border-radius: 5px;
  .logoutTv {
    margin-left: 5px;
    color: #222732;
    font-size: 1rem;
  }
}

.redSpan{
  max-width: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #F42A2A;
}
</style>
